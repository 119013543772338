import { Injectable, Injector } from "@angular/core";
import { ID } from "@common/interfaces/id";

import { IssueQuery } from "./issue/issue.query";
import { IIssueContext, InterfaceDataContext } from "@common/utils/workflowDog";

import { interfaceDataQueryMap, interfaceDataServiceMap } from './interfaceDataMapping'
import { InterfaceNameValue } from "@common/interfaces/issueTypeInterface";
import { combineLatest, Observable } from "rxjs";
import { IBaseInterfaceData } from "@common/interfaces/base";
import { filter, map } from "rxjs/operators";
import { IIssue } from "@common/interfaces/issue";
import { IssueTypeInterfaceQuery } from "./issueTypeInterface/issueTypeInterface.query";
import { UserQuery } from "./user/user.query";
import { IUser } from "@common/interfaces/user";

@Injectable({
  providedIn: 'root'
})
export class IssueContextQuery {

  constructor(private issueQuery: IssueQuery,
    private injector: Injector,
    private issueTypeInterfaceQuery: IssueTypeInterfaceQuery,
    private userQuery: UserQuery) { }

  getIssueContext(issueId: ID): IIssueContext {
    const issue = this.issueQuery.getEntity(issueId);
    let reporter: IUser;
    let assignee: IUser;

    if (issue.assigneeId) {
      assignee = this.userQuery.getEntity(issue.assigneeId);
    }
    if (issue.reporterId) {
      reporter = this.userQuery.getEntity(issue.reporterId);
    }
    const interfaceData: Partial<InterfaceDataContext> = {};
    const interfaces = Object.keys(interfaceDataQueryMap);
    for (const interfaceName of interfaces) {
      const query = this.injector.get(interfaceDataQueryMap[interfaceName]);
      const result = query.getByIssueId(issueId);
      interfaceData[interfaceName] = result;
    }

    return { issue, reporter, assignee, interfaceData: interfaceData as InterfaceDataContext }
  }

  getIssueContextByTaskType(issueId: ID, typeId: ID): IIssueContext {
    const issue = this.issueQuery.getEntity(issueId);
    let reporter: IUser;
    let assignee: IUser;
    if (issue.assigneeId) {
      assignee = this.userQuery.getEntity(issue.assigneeId);
    }
    if (issue.reporterId) {
      reporter = this.userQuery.getEntity(issue.reporterId);
    }
    const interfaceData: Partial<InterfaceDataContext> = {};
    const issueTypeInterfaces = this.issueTypeInterfaceQuery.getByIssueTypeId(typeId)
    const interfaces = issueTypeInterfaces.map(typeInterfaces => typeInterfaces.interfaceName);
    for (const interfaceName of interfaces) {
      const query = this.injector.get(interfaceDataQueryMap[interfaceName]);
      const result = query.getByIssueId(issueId);
      interfaceData[interfaceName] = result;
    }

    return { issue, reporter, assignee, interfaceData: interfaceData as InterfaceDataContext }
  }

  cleanIssueContext(issueId: ID,): void {
    const interfaces = Object.keys(interfaceDataQueryMap);
    for (const interfaceName of interfaces) {
      const service = this.injector.get(interfaceDataServiceMap[interfaceName]);
      service.localRemoveByIssue(issueId);
    }
  }

  selectIssueContext$(issueId: ID): Observable<IIssueContext> {
    const interfaceObservables: Observable<{
      interfaceName: InterfaceNameValue,
      interfaceData: IBaseInterfaceData[]
    }>[] = [];

    const interfaces = Object.keys(interfaceDataQueryMap);
    for (const interfaceName of interfaces) {
      const query = this.injector.get(interfaceDataQueryMap[interfaceName]);

      const observable = query.selectByIssueId$(issueId).pipe(
        map(interfaceData => {
          return { interfaceName, interfaceData }
        })
      )

      interfaceObservables.push(observable);
    }

    return combineLatest([
      this.issueQuery.issueById$(issueId).pipe(filter(issue => !!issue)),
      ...interfaceObservables
    ]).pipe(
      map((array) => {
        const interfaceData: Partial<InterfaceDataContext> = {};
        let issue: IIssue;
        let reporter: IUser;
        let assignee: IUser;

        for (const elem of array) {
          if (isIssue(elem)) {
            issue = elem;
            if (issue.assigneeId) {
              assignee = this.userQuery.getEntity(issue.assigneeId);
            }
            if (issue.reporterId) {
              reporter = this.userQuery.getEntity(issue.reporterId);
            }
          } else {
            interfaceData[elem.interfaceName] = elem.interfaceData;
          }
        }

        return {
          issue,
          reporter,
          assignee,
          interfaceData: interfaceData as InterfaceDataContext
        }
      })
    )

  }
}


/**
   * DO NOT USE OUTSIDE THIS FILE
   * @param issue
   * @returns
   */
function isIssue(issue: any): issue is IIssue {
  return (issue.id && issue.stateId);
}
