import { Injectable } from '@angular/core';
import { ID } from '@common/interfaces/id';
import { FilterStore, IFilter, createInitialFilterState } from './filter.store';
import { AppService } from '@ep-om/app.service';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureDisplay } from '@common/features';
import { FilterQuery } from './filter.query';
import { IIssue } from '@common/interfaces/issue';
import { AuthService } from '@ep-om/project/auth/auth.service';
import { IssueUtil } from '@common/utils/issue';
import { EntityInteractionQuery } from '../entityInteraction/entityInteraction.query';
import { compareAsc } from 'date-fns';
import { ProjectQuery } from '../project/project.query';
import { IProject } from '@common/interfaces/project';

export type Filters = {
  tag: boolean;
  issueType: boolean;
  issueState: boolean;
  milestone: boolean;
  reporter: boolean;
  assignee: boolean;
  priority: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  settings$: Observable<Filters>;

  constructor(
    private store: FilterStore,
    public query: FilterQuery,
    private appService: AppService,
    private authService: AuthService,
    private interactionsQuery: EntityInteractionQuery,
    private projectQuery: ProjectQuery
  ) {
    this.settings$ = combineLatest([
      this.appService.features$,
      this.projectQuery.activeNotNull$
    ]).pipe(
      map<[FeatureDisplay, IProject], Filters>(([features, project]) => {
        const filter = this.query.getEntity(project.id);
        return {
          assignee: filter.assignee.length > 0,
          milestone: filter.milestone.length > 0 && features.milestone !== 'DISABLED',
          reporter: filter.reporter.length > 0,
          issueState: filter.issueState.length > 0,
          tag: filter.tag.length > 0 && features.tags !== 'DISABLED',
          issueType: filter.issueType.length > 0,
          priority: filter.priority.length > 0
        }
      })
    );
  }

  initializeFilter(projectId: string) {
    if (!projectId || !!this.query.getEntity(projectId)) {
      return;
    }
    this.store.add(createInitialFilterState(projectId));
  }

  updateSearchConfig(projectId: string, interfaces: string[]) {
    this.store.update(projectId, state => ({
      ...state,
      settings: {
        ...state.settings || {},
        search: interfaces,
      }
    }));
  }

  updateSearchTerm(projectId: string, searchTerm: string) {
    this.store.update(projectId, (state) => {
      return {
        ...state,
        searchTerm
      }
    });
  }

  toggleAssignee(projectId: string, userId: string) {
    this.store.update(projectId, (state) => {
      const hasUser = state.assignee.includes(userId);
      const assignee = hasUser
        ? state.assignee.filter((x) => x !== userId)
        : [...state.assignee, userId];
      return {
        ...state,
        assignee
      };
    });
  }

  toggleReporter(projectId: string, userId: string) {
    this.store.update(projectId, (state) => {
      const hasUser = state.reporter.includes(userId);
      const reporter = hasUser
        ? state.reporter.filter((x) => x !== userId)
        : [...state.reporter, userId];
      return {
        ...state,
        reporter
      };
    });
  }

  toggleOnlyNews(projectId: string) {
    this.store.update(projectId, (state) => {
      const onlyNews = !state.onlyNews;
      return {
        ...state,
        onlyNews
      }
    })
  }

  toggleOnlyMyIssue(projectId: string) {
    this.store.update(projectId, (state) => {
      const onlyMyIssue = !state.onlyMyIssue;
      return {
        ...state,
        onlyMyIssue
      };
    });
  }

  toggleNearDueDate(projectId: string) {
    this.store.update(projectId, state => {
      const nearDueDate = !state.nearDueDate;
      return {
        ...state,
        nearDueDate
      };
    });
  }

  resetAll(projectId: string) {
    this.store.update(projectId, state => {
      const { settings, ...initialState } = createInitialFilterState(state.projectId);
      return {
        ...state,
        ...initialState
      };
    });
  }

  toggleTag(projectId: string, id: ID) {
    return this.store.update(projectId, (state) => {
      const tagSelected = state.tag.some(filterId => filterId === id);
      if (tagSelected) {
        const tag = state.tag.filter(tag => tag !== id);
        return {
          ...state,
          tag
        }
      }
      return {
        ...state,
        tag: [...state.tag, id]
      }
    });
  }

  toggleMilestone(projectId: string, id: ID) {
    return this.store.update(projectId, (state) => {
      const milestoneSelected = state.milestone.some(filterId => filterId === id);
      if (milestoneSelected) {
        const milestone = state.milestone.filter(milestone => milestone != id);
        return {
          ...state,
          milestone
        }
      }
      return {
        ...state,
        milestone: [...state.milestone, id]
      }
    });
  }

  toggleIssueState(projectId: string, id: ID) {
    return this.store.update(projectId, (state) => {
      const issueStateSelected = state.issueState.some(filterID => filterID === id);
      if (issueStateSelected) {
        const issueState = state.issueState.filter(issueState => issueState != id);
        return {
          ...state,
          issueState
        }
      }
      return {
        ...state,
        issueState: [...state.issueState, id]
      }
    });
  }

  toggleIssueType(projectId: string, id: ID) {
    return this.store.update(projectId, (state) => {
      const issueTypeSelected = state.issueType.some(filterID => filterID === id);
      if (issueTypeSelected) {
        const issueType = state.issueType.filter(issueType => issueType != id);
        return {
          ...state,
          issueType
        }
      }
      return {
        ...state,
        issueType: [...state.issueType, id]
      }
    });
  }

  togglePriority(projectId: string, priorityName: string) {
    this.store.update(projectId, (state) => {
      const prioritySelected = state.priority.includes(priorityName)
      const priority = prioritySelected
        ? state.priority.filter((x) => x !== priorityName)
        : [...state.priority, priorityName];
      return {
        ...state,
        priority
      };
    });
  }

  filterIssues<T extends IIssue>(issues: T[], filter: IFilter): T[] {
    const { onlyMyIssue, onlyNews, nearDueDate, searchTerm, assignee, reporter, issueState, issueType, tag, milestone, priority } = filter;
    const currUserId = this.authService.authQuery.getLoggedUserId();
    const interactions = this.interactionsQuery.getAll();
    return issues.filter((issue) => {
      const isMyIssue = () => onlyMyIssue ? currUserId && (issue.assigneeId === currUserId || issue.reporterId === currUserId || issue.createdBy === currUserId || issue.notificationSubscribers.includes(currUserId)) : true;
      const isNearDueDate = () => nearDueDate ? IssueUtil.isNearDueDate(issue) : true;
      const isNew = () => {
        if (!onlyNews) {
          return true;
        }
        if (issue.lastUpdatedBy === currUserId) {
          return false;
        }
        return !interactions.some(interaction => interaction.issueId === issue.id && interaction.userId === currUserId && compareAsc(new Date(issue.updatedAt), new Date(interaction.time)) < 1)
      }
      const isTagged = () => tag.length > 0 ? issue.tagIds?.some(id => tag.includes(id)) : true;
      const isMilestoned = () => milestone.length > 0 ? milestone.includes(issue.milestoneId) : true;
      const isAssigned = () => assignee.length > 0 ? assignee.includes(issue.assigneeId) : true;
      const isReported = () => reporter.length > 0 ? reporter.includes(issue.reporterId) : true;
      const isStated = () => issueState.length > 0 ? issueState.includes(issue.stateId) : true;
      const isTyped = () => issueType.length > 0 ? issueType.includes(issue.typeId) : true;
      const isPriority = () => priority.length > 0 ? priority.includes(issue.priority) : true;

      return isMyIssue() && isNearDueDate() && isNew() && isTagged() && isMilestoned() && isAssigned() && isReported() && isStated() && isTyped() && isPriority() &&
        (this.query.isFoundInterfaceFormData(issue, ["id", "version", "createdAt", "updatedAt", "isValid", "deletedAt"]));
    });
  }

}
